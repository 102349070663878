<template>
  <div id="nav">
    <div class="div-download">
      <div class="div-logo-header">
        <img class="img-logo" src="~@/assets/ic_launcher.png" />
        <p class="app-name">墨记桌面版本下载</p>
        <p class="app-desc"></p>
        <a class="link-moji-web" href="http://www.moji01.com" target="_blank"
          >回到主页</a
        >
      </div>
    </div>

    <div class="share_root" v-if="!isError">
      <div class="diary-card-root">
        <div class="div-download-tip" v-if="platform">
          <p class="p-download">即将下载更新…</p>
          <p class="p-tip">如等待多时未自动开始下载，请选择对应版本下载安装</p>
        </div>

        <div class="div-download-platform">
          <div class="div-platform">
            <div class="div-platform-logo">
              <img src="~@/assets/ic_mac.svg" />
              <p class="p-platform">Mac-intel芯片</p>
            </div>
            <div class="btn-download" @click="downloadMacIntel()">立即下载</div>
          </div>

          <div
            class="div-platform"
            style="margin-left: 136px; margin-right: 136px"
          >
            <div class="div-platform-logo">
              <img src="~@/assets/ic_mac.svg" />
              <p class="p-platform">Mac-Apple芯片</p>
            </div>
            <div class="btn-download" @click="downloadMacArm()">立即下载</div>
          </div>

          <div class="div-platform">
            <div class="div-platform-logo">
              <img src="~@/assets/ic_windows.svg" />
              <p class="p-platform">Windows</p>
            </div>
            <div class="btn-download" @click="downloadWin()">立即下载</div>
          </div>
        </div>

        <div class="div-install-link">
           <a class="link-install-web" href="http://mojishare.mojime.cn/?keyValue=ZaZ1mYNKzmtXlwrj8250urGXIGu7z97DJV6CpOd/HYQ=" target="_blank">Mac安装教程</a>
           <a class="link-install-web" href="http://mojishare.mojime.cn/?keyValue=ZaZ1mYNKzmtXlwrj8250unyWnDjvPtpwT2BbaGVM11E=" target="_blank">Windows安装教程</a>
        </div>

        <div class="div-install-link">
           <a class="link-install-web" href="https://pan.baidu.com/s/1tVZYHwA67BPlHLUxJUWyLQ" target="_blank">百度网盘下载，提取码：xk2f</a>
        </div>

        <div class="div-update-info">
          <p class="p-download">墨记1.1.6发布</p>
          <p class="p-message">发布日期：2024-05-20</p>
          <p class="p-message">
            墨记是一款精美排版的日记、笔记应用，拥有设计简洁、简单易用的特点，却同时拥有强大的功能！墨记支持：Mac、Windows、Android、iOS等多平台高效协作
          </p>
          <p class="p-message">该版本主要更新如下：</p>

          <p class="p-update-item">1. 日记支持自动保存，不再担心日记丢失</p>
          <p class="p-update-item">2. 支持Windows/Mac/iPhone/Android/iPad...多端同时使用，随时备份安全同步</p>
          <p class="p-update-item">3. 段落改编辑器，简洁方便易上手</p>
          <p class="p-update-item">4. 图文混排、日记信纸，丰富记录体验</p>
          <p class="p-update-item">5. 支持分类管理日记，分门别类更清晰</p>
          <p class="p-update-item">6. 一键生成分享链接，随时随地轻松分享</p>
          <p class="p-update-item">7. 精美日历视图，翻阅补记一眼就够了</p>
          <p class="p-update-item">8. 支持根据关键字词搜索相关日记，快捷准确</p>
        </div>
      </div>
    </div>

    <div class="error-page" v-if="isError">
      <p class="error-info">{{ errorInfo }}</p>
    </div>

    <div class="download-toast" v-if="isShowToast">
        即将开始下载，如安装过程中出现问题，请阅读安装教程
    </div>

    <div class="root-loading-mask" v-if="isShowLoadingDialog">
      <div class="root-loading">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
import Constanct from "./Constanct.js";
import axios from "axios";
var qs = require("qs");
export default {
  data() {
    return {
      platform: "",
      downloadInfo: {},
      isError: false,
      isShowToast: false,
      isShowLoadingDialog: true,
    };
  },
  components: {},
  name: "DLFile",
  created() {
    let that = this;
    that.getDownloadUr();
    //http://192.168.3.245:8080/download?platform=win
    let arr = window.location.href.split("?");
    if (arr.length <= 1) {
      return;
    }
    let params = arr[1];

    let paramsMap = new Map();
    let cts = params.split("&");
    for (const colunm of cts) {
      let is = colunm.split("=");
      if (is != null && is.length > 1) {
        paramsMap.set(is[0], is[1]);
      }
    }

    this.platform = paramsMap.get("platform");
    if (this.isEmpty(this.platform)) {
      return;
    }
  },
  methods: {
    getDownloadUr() {
      let that = this;

      let obj = qs.stringify({ keyValue: "" });

      axios
        .post(`${Constanct.getUrl()}SysSetting/PostDownLoadInfo`, obj, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((response) => {
          this.isShowLoadingDialog = false;
          let bean = response.data;
          if (bean.Status != 1) {
            //that.isError = true;
            //that.errorInfo = bean.Message;
            return;
          }
          that.downloadInfo = JSON.parse(bean.Result);

          console.log("JSON", that.downloadInfo);

          if (that.isEmpty(that.platform)) {
            return;
          }
          if ("win" == that.platform) {
            this.downloadWin();
          } else if ("macIntel" == that.platform) {
            this.downloadMacIntel();
          } else if ("macArm" == that.platform) {
            this.downloadMacArm();
          }
        })
        .catch((error) => {
          console.log("error", error); //请求失败返回的数据
        });
    },

    requestUrl() {},
    download(url) {
      console.log("正式下载", url);
      let downLoadLink = document.createElementNS(
        "http://www.w3.org/1999/xhtml",
        "a"
      );
      downLoadLink.href = `${url}`;
      downLoadLink.download = "moji";
      downLoadLink.click();
    },

    isEmpty(str) {
      return typeof str == "undefined" || str == null || str == "";
    },

    downloadMacIntel() {
      if (
        this.downloadInfo.macIntel == null ||
        this.downloadInfo.macIntel == undefined
      ) {
        return;
      }
      this.download(this.downloadInfo.macIntel.url);
      let that = this;
      that.isShowToast = true;
      setTimeout(() => {
        that.isShowToast = false;
      },3500)
    },
    downloadMacArm() {
      if (
        this.downloadInfo.macArm == null ||
        this.downloadInfo.macArm == undefined
      ) {
        return;
      }
      this.download(this.downloadInfo.macArm.url);
      let that = this;
      that.isShowToast = true;
      setTimeout(() => {
        that.isShowToast = false;
      },3500)
    },
    downloadWin() {
      if (this.downloadInfo.win == null || this.downloadInfo.win == undefined) {
        return;
      }
      this.download(this.downloadInfo.win.url);
      let that = this;
      that.isShowToast = true;
      setTimeout(() => {
        that.isShowToast = false;
      },3500)
    },
  },
};
</script>

<style scoped>
#nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.div-download {
  width: 100%;
  height: 90px;
  background-color: #1b1c23;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  z-index: 300;
  display: flex;
  justify-content: center;
  align-items: center;
}

.div-logo-header {
  display: flex;
  align-items: center;
  height: 90px;
  width: 100%;
  max-width: 800px;
}

.download-toast {
  z-index: 200;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  height: 32px;
  width: fit-content;
  padding-left: 32px;
  padding-right: 32px;
  color: #ffffff;
  top: 0;
  margin-top: 400px;
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  border-radius: 16px;
}

.app-name {
  color: #ffffff;
  font-size: 20px;
  font-weight: 800;
  margin: 0px;
  text-align: left;
}

.app-desc {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  margin-left: 12px;
  flex: 1;
  text-align: left;
}

.img-logo {
  height: 52px;
  width: 52px;
  margin-right: 22px;
}

.link-moji-web {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  width: 100px;
  height: 32px;
  cursor: pointer;
  border-radius: 16px;
  border: 1px solid #ffffff;
}

.div-install-link{
  display: flex;
  margin-top: 52px;
  width: 100%;
  margin-left: 76px;
}

.link-install-web {
  color: #00CCBB;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 52px;
  width: fit-content;
  height: 46px;
  cursor: pointer;
  border-radius: 23px;
}

.share_root {
  width: 100%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 90px);
  overflow-y: auto;
  overflow-x: hidden;
}

.diary-card-root {
  display: flex;
  margin-top: 32px;
  margin-bottom: 32px;
  height: calc(100% - 64px);
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 800px;
  min-width: 300px;
  background: #ffffff;
  user-select: text !important;
  background-size: 100px 100px;

}

.div-download-tip {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 36px;
}

.p-download {
  color: #1b1c23;
  font-size: 26px;
  font-weight: 600;
}

.p-tip {
  color: #1b1c23;
  font-size: 16px;
  margin-top: 0px;
  font-weight: 400;
  margin-bottom: 0px;
}

.div-download-platform {
  display: flex;
  margin-top: 42px;
  justify-content: center;
  align-items: center;
}

.div-platform {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.div-platform-logo {
  display: flex;
  height: 130px;
  width: 130px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #1b1c23;
}

.div-platform-logo img {
  height: 40px;
  width: 40px;
  margin-bottom: 10px;
}

.p-platform {
  color: white;
  font-size: 16px;
  margin-top: 0px;
  font-weight: 600;
  margin-bottom: 0px;
}

.btn-download {
  cursor: pointer;
  border-radius: 6px;
  background: #1b1c23;
  height: 36px;
  width: 110px;
  margin-top: 20px;
  color: white;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.btn-download:hover {
  background: #48494f;
}

.div-update-info {
  width: calc(100% - 152px);
  display: flex;
  margin-top: 52px;
  margin-left: 76px;
  margin-right: 76px;
  flex-direction: column;
  justify-content: flex-start;
}

.div-update-info p {
  text-align: left;
}

.p-update-item {
  font-weight: 600;
  margin-top: 4px;
  margin-bottom: 4px;
}


.root-loading-mask{
     z-index: 1500;
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
}
.root-loading {
    width: 180px;
    height: 50px;
    text-align: center;
    position: absolute;
    left: 50%;
	top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
	transform: translate(-50%, -50%);
     background: #FFFFFF;
     border-radius: 4px;
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.root-loading span {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 10px;
    background: #1B1C23;
    border-radius: 50%;
    animation: load 1.04s ease infinite;
}

.root-loading span:last-child {
    margin-right: 0px;
}

@keyframes load {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.root-loading span:nth-child(1) {
    animation-delay: 0.13s;
}

.root-loading span:nth-child(2) {
    animation-delay: 0.26s;
}

.root-loading span:nth-child(3) {
    animation-delay: 0.39s;
}

.root-loading span:nth-child(4) {
    animation-delay: 0.52s;
}

.root-loading span:nth-child(5) {
   animation-delay: 0.65s;
}
</style>
