const ENV_URL = "http://pcapi.moji01.com/api/";

//const ENV_URL = "http://192.168.0.11:8080/api/"


export function getUrl() {
  return ENV_URL;
}


export default {
  getUrl,
};
